/*IMPORT*/

@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/*------------------------------------------------------------------------------------------------------*/
/*COOKIE BANNER*/
.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgb(89, 89, 89);
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index:9999;
  }
  
  .cookie-content {
    max-width: auto;
    margin: 0 auto;
    font-size: 12px !important;
  }
  
  .cookie-buttons button {
    margin: 0 10px;
    padding: 8px 15px;
    cursor: pointer;
  }
  .cookiea{
    color: blue !important;
    text-decoration: none !important;
  }
  .cookiea:hover{
color: black !important;
  }
/*FINE COOKIE BANNER*/

/*NAVBAR*/
a{
    text-decoration: none !important;
}




.sfondoLoading {
    background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.3) 50%, transparent 0), linear-gradient(rgba(200, 0, 0, 0.4) 50%, transparent 0);
    background-size: 50px 50px !important;
    height: 100%;
    width: 100%;
    position: fixed;
}
.sfondoMenuPdf{
    background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.3) 50%, transparent 0), linear-gradient(rgba(200, 0, 0, 0.4) 50%, transparent 0) !important;
    background-size: 50px 50px !important;
    height: 100%;
    width: 100%;

}
.fa-solid .fa-user-shield{
    color: white;
}
  .logoload {
    margin-top: 10% !important;
    margin-bottom: auto !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 250px !important;
    height: 250px !important;
    position: relative;
    top: 50% !important;
    z-index: 9991;
    filter: none !important; /* Explicitly disable filter effects */
  }
  @media screen and (max-width: 600px) {
    .logoload {
      margin-right: auto !important;
      margin-left: auto !important;
      width: 250px !important;
      height: 250px !important;
      position: relative;
      z-index: 9991;
      filter: none !important; /* Explicitly disable filter effects */
    }
  }
  
  .nameload{
    font-size: 3.0em;
    font-weight: 300;
    font-family: 'Space Grotesk', sans-serif;
    filter: none !important; /* Explicitly disable filter effects */
    color: black;
    font-family: 'Great Vibes', cursive !important;
  }
  @media screen and (max-width: 600px) {
    .nameload{
        font-size: 2.0em;
        font-weight: 300;
        font-family: 'Space Grotesk', sans-serif;
        filter: none !important; /* Explicitly disable filter effects */
        color: black;
        font-family: 'Great Vibes', cursive !important;
      }  
}

  .cornice1{
    border: 2px solid #000; /* Spessore, tipo e colore del bordo */
    padding: 10px; /* Spazio interno tra il testo e il bordo */
    display: inline-block; /* Per far sì che il bordo si adatti alle dimensioni del contenuto */    
    margin-top: 10% !important;
}
.cornice {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px; /* Puoi regolare la larghezza massima a tuo piacimento */
    padding: 5px;
    border: 2px solid black;
    background: white;
}
  
  .cornice:before,
  .cornice:after {
    content: "•";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 14px;
    color: black;
    border: 2px solid black; /* Se $color-alpha è una variabile Sass, dovresti fornire il suo valore qui */
    line-height: 12px;
    top: 5px;
    text-align: center;
  }
  
  .cornice:before {
    left: 5px;
  }
  
  .cornice:after {
    right: 5px;
  }
  
  .cornice .cornice-inner {
    position: relative;
    border: 2px solid black; /* Se $color-alpha è una variabile Sass, dovresti fornire il suo valore qui */
    padding: 40px;
  }
  
  .cornice .cornice-inner:before,
  .cornice .cornice-inner:after {
    content: "•";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 14px;
    color: black; /* Se $color-alpha è una variabile Sass, dovresti fornire il suo valore qui */
    border: 2px solid black; /* Se $color-alpha è una variabile Sass, dovresti fornire il suo valore qui */
    line-height: 12px;
    bottom: -2px;
    text-align: center;
  }
  
  .cornice .cornice-inner:before {
    left: -2px;
  }
  
  .cornice .cornice-inner:after {
    right: -2px;
  }
  

  
  

.navbar {
    width: 100%;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.test1 {
    background-color: white !important;
}

.navbar-nav {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

@media screen and (min-width: 1240px) {
    .navbar .navbar-nav .nav-link {
        font-size: 2.3em !important;
    }
}

@media screen and (min-width: 1340px) {
    .navbar .navbar-nav .nav-link {
        font-size: 2.4em !important;
    }
}

@media screen and (min-width: 1430px) {
    .navbar .navbar-nav .nav-link {
        font-size: 2.5em !important;
    }
}

@media screen and (min-width: 1539px) {
    .navbar .navbar-nav .nav-link {
        font-size: 2.6em !important;
    }
}

@media screen and (min-width: 1658px) {
    .navbar .navbar-nav .nav-link {
        font-size: 2.7em !important;
    }
}

@media screen and (min-width: 1850px) {
    .navbar .navbar-nav .nav-link {
        font-size: 2.9em !important;
    }
}

.navbar .navbar-nav .nav-link {
    color: #000000;
    font-size: 2.2em;
    font-family: 'Bebas Neue', cursive;
    margin: -18px 0 -25px 0 !important;
    padding: 0 5 0 0 !important;
    flex-direction: row !important;
    display: block;
}

@media screen and (max-width: 1175px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 2.15em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 1100px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 2.05em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 1022px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 2.0em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 954px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.85em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 895px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.8em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -30px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 830px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.7em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -30px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 778px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.55em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 678px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.45em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 608px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.35em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding: 0 5 0 0 !important;
        display: inline-block;
    }
}

@media screen and (max-width: 576px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.30em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding-top: 0px !important;
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
    }
}

@media screen and (max-width: 475px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.20em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
    }

    .noImg {
        background-image: url('./img/AngoloLogo.png');
        background-size: cover;
        background-position: center !important;
        background-repeat: no-repeat;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: 50px;
    }
}

@media screen and (max-width: 420px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.2em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
    }
}

@media screen and (max-width: 400px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.1em;
        font-family: 'Bebas Neue', cursive;
        margin: -18px 0 -25px 0 !important;
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
    }
}

@media screen and (max-width: 376px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 1.05em !important;
        font-family: 'Bebas Neue', cursive;
        margin: -25px 0 -25px 0 !important;
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
    }
}

@media screen and (max-width: 330px) {
    .navbar .navbar-nav .nav-link {
        color: #000000;
        font-size: 0.9em !important;
        font-family: 'Bebas Neue', cursive;
        margin: -25px 0 -25px 0 !important;
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
    }
}

.contrasto:hover {
    color: rgb(165, 34, 34) !important;
}

.contrasto1:hover {
    color: #063971 !important;
}

.nav-link {
    display: inline-block !important;
    /*Mi permette di avere gli elementi delle Navbar allineati*/
    flex-direction: row;
    text-align: center;
}

.nav-link-dropdown {
    font-size: 1em;
    font-family: 'Bebas Neue', cursive;
    margin: -10px 0 -10px 0 !important;
    padding: 0 5 0 0 !important;
    color: black;
    text-align: center;
    text-decoration: none !important;
    z-index: 99999 !important;
}

.nav-link-dropdown:hover {
    font-size: 1.1em;
    font-family: 'Bebas Neue', cursive;
    margin: -10px 0 -10px 0 !important;
    padding: 0 5 0 0 !important;
    color: rgb(165, 34, 34);
    background-color: transparent;
    text-align: center;
}

.dropdown-toggle::after {
    color: black;
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

@media screen and (max-width: 576px) {
    .nav-link-dropdown {
        font-size: 0.8em;
        font-family: 'Bebas Neue', cursive;
        padding: 0 5 0 0 !important;
        color: black;
        text-align: center;
    }
    .nav-link-dropdown:hover {
        font-size: 0.9em;
        font-family: 'Bebas Neue', cursive;
        padding: 0 5 0 0 !important;
        color: rgb(165, 34, 34);
        background-color: transparent;
        text-align: center;
    }
}

@media screen and (max-width: 350px) {
    .nav-link-dropdown {
        font-size: 0.6em;
        font-family: 'Bebas Neue', cursive;
        padding: 0 5 0 0 !important;
        color: black;
        text-align: center;
    }
    .nav-link-dropdown:hover {
        font-size: 0.8em;
        font-family: 'Bebas Neue', cursive;
        padding: 0 5 0 0 !important;
        color: rgb(165, 34, 34);
        background-color: transparent;
        text-align: center;
    }
}


/*FINE NAVBAR*/


/*------------------------------------------------------------------------------------------------------*/


/*------------------------------------------------------------------------------------------------------*/


/*BOTTONE "SCARICA MENU EFFETTO SHACKER*/

.box {
    display: inline-block;
    position: relative;
}

.box img {
    width: 100%;
    height: auto;
    position: relative;
}

.box a {
    position: absolute;
    bottom: 0.5em;
    left: 1em;
    font-family: 'Bebas Neue', cursive;
    padding: 10px;
    color: white;
    background: rgb(165, 34, 34);
    display: block;
}

.box a:hover {
    color: rgb(165, 34, 34);
    background: white;
    border: none;
}

@media screen and (max-width: 576px) {
    .box a {
        position: absolute;
        bottom: 0.5em;
        left: 1em;
        font-family: 'Bebas Neue', cursive;
        font-size: small;
        padding: 5px;
        color: white;
        background: rgb(165, 34, 34);
        display: block;
    }
}


/*SHAKER*/

@keyframes wiggle {
    2% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    4% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    6% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    8% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    10% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    12% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    14% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    16% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    18% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }
    20% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }
}

.wiggle {
    display: inline-block;
    animation: wiggle 2.7s infinite;
}

.wiggle:hover {
    animation: none;
}


/*FINE SHAKER*/


/* FINE BOTTONE "SCARICA MENU EFFETTO SHACKER*/


/*------------------------------------------------------------------------------------------------------*/


/*------------------------------------------------------------------------------------------------------*/


/*TITOLI CON EFFETTO DI ENTRATA*/

.titoloStoria {
    font-family: 'Bebas Neue', cursive;
    font-size: 5.5em;
    text-align: left;
}

.contenitoreMinimo {
    overflow: hidden !important;
}

.titoloStoria1 {
    font-family: 'Bebas Neue', cursive;
    font-size: 5.5em;
    text-align: right;
    overflow: hidden;
    margin: 0 !important;
}

@media screen and (max-width: 576px) {
    .titoloStoria {
        font-size: 3.5em;
    }
}

@media screen and (max-width: 350px) {
    .titoloStoria {
        font-size: 3.0em;
    }
}

@media screen and (max-width: 281px) {
    .titoloStoria {
        font-size: 2.0em;
    }
}


/*FINE TITOLI CON EFFETTO DI ENTRATA*/


/*------------------------------------------------------------------------------------------------------*/


/*------------------------------------------------------------------------------------------------------*/


/*CAROSELLO IMMAGINI + STORIA*/

.marginColonna {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
}

.caroselloResize {
    width: 100%;
    height: 400px;
    max-height: 400px;
}

@media screen and (max-width: 576px) {
    .caroselloResize {
        height: 350px;
    }
}

.centro {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    padding: 100px 0;
    max-height: 350px;
}

.storia {
    font-family: 'Economica', sans-serif;
    text-align: center;
    vertical-align: middle;
    font-size: calc(0.8em + 1vmin);
    overflow: auto;
    max-height: 350px;
    text-size-adjust: auto;
    width: 90%;
}

@media screen and (max-width: 986px) {
    .centro {
        padding: 25px 0 25px 0;
        min-width: 100%;
        max-height: 350px !important;
    }
    .storia {
        overflow: auto !important;
        font-size: calc(0.6em + 1vmin);
    }
}

@media screen and (max-width: 500px) {
  
    .storia {
        overflow: auto !important;
        font-size: calc(0.8em + 1vmin);
    }
}

.michelin {
    background-color: transparent !important;
    color: yellow;
    padding: 0 !important;
    margin: 0 !important;
}

.imgLogo {
    width: 100%;
    height: 70px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    z-index: 999;
}

@media screen and (max-width: 870px) {
    .imgLogo {
        width: 100%;
        height: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-sizing: border-box;
        z-index: 999;
    }
}


/*FINE CAROSELLO IMMAGINI + STORIA*/


/*------------------------------------------------------------------------------------------------------*/


/*------------------------------------------------------------------------------------------------------*/


/*BOTTONI MENU E MENU*/

.menu {
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
    width: 100% !important;
    height: 200px;
}

.BtnMenu {
    font-family: 'Bebas Neue', cursive;
    text-align: center;
    align-items: center !important;
    position: relative;
    margin-top: 50px;
    border: none;
}

.photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    grid-auto-rows: 200px;
}

@media screen and (max-width: 600px) {
    .photo-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
        grid-auto-rows: 100px;
    }
    .card1-tall {
        grid-row: span 2/auto;
    }
    .card1-wide {
        grid-column: span 2/auto;
    }
}

.card1 {
    display: flex;
    justify-content: center; /* Centra orizzontalmente */
    align-items: center; /* Centra verticalmente */
    border: 1px solid black !important;
    border-radius: 0px !important;
    font-family: 'Bebas Neue', cursive;
    color: white !important;
    font-size: 3.5vw;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .card1 {
        font-size: 5.5vw !important; 
    }
}

.cardCompleto {
    display: flex; /* Aggiunto per centrare il testo */
    justify-content: center; /* Centra orizzontalmente */
    align-items: center; /* Centra verticalmente */
    border: 1px solid black !important;
    border-radius: 0px !important;
    font-family: 'Bebas Neue', cursive;
    color: white !important;
    font-size: 5.0vw !important;
    text-align: center;
}



@media screen and (max-width: 600px) {
    .card1 {
        font-size: 3.5vw;
    }
    .cardCompleto {
        font-size: 9.0vw !important;
    }
}

.button {
    border: none !important;
    background-color: transparent !important;
    color: white;
    text-decoration: none !important;
}

a:hover {
    color: white;
}

.imgSize {
    background-size: cover;
    overflow: hidden;
}

.imgSize {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    width: 100%;
}

.imgSize:hover {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}


/*FINE BOTTONI MENU*/


/*------------------------------------------------------------------------------------------------------*/


/*MENU*/

.sfondoMenu {
    background-image: url("img/blackboard2.avif");
    background-size: cover;
    background-repeat: repeat !important;
    background-position: center center;
}

.responsive1 {
    padding: 0 0 0 0px !important;
    float: left;
    width: 48%;
    margin-left: 1% !important;
}

@media screen and (max-width: 600px) {
    .responsive1 {
        padding: 0 0 0 0px !important;
        float: left;
        width: 48%;
        margin-left: 5px !important;
    }
}

@media screen and (max-width: 300px) {
    .responsive1 {
        padding: 0 0 0 0px !important;
        float: left;
        width: 48%;
        margin-left: 2px !important;
    }
}

@media screen and (max-width: 681px) {
    .card-title {
        font-size: 1.8vw !important;
    }
}
@media screen and (max-width: 500px) {
    .card-title {
        font-size: 2.0vw !important;
    }
}

@media screen and (max-width: 341px) {
    .card-title {
        font-size: 1.8vw !important;
    }
}

.megCentrato {
    width: 100%;
    font-size: 5vw;
    text-align: center;
    position: relative;
    color: white;
    margin: 0 0 10px 0 !important;
    font-family: 'Fredericka the Great', cursive;
    justify-content: center;
    flex-direction: column;
}

.card-title {
    text-align: left !important;
    font-size: 1.8vw;
    color: white !important;
    font-family: 'Fredericka the Great', cursive !important;
    font-weight: normal !important;
    margin: 10px 0 10px -20px !important;
    padding: 0 0 0 0 !important;
    justify-content: center;
    background-color: transparent !important;
    z-index:9999;
}

.top {
    font-size: 1.6vw;
    color: white !important;
    font-family: 'Fredericka the Great', cursive !important;
    font-weight: normal !important;
    position: relative;
    padding-bottom: 0px !important;
    background-color: transparent !important
}
@media screen and (max-width: 681px) {
    .top {
        font-size: 2.0vw;
    }
}

* {
    box-sizing: border-box;
}

.clearfix:after {
    background-image: none !important;
    content: "";
    display: table;
    clear: both;
    margin: -10px 0 0 0 !important;
    padding: 0 0 0 0 !important;
}




/*------------------------------------------------------------------------------------------------------*/


/*CAROSELLO */

.imgCarousel {
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 3% !important;
    z-index: 999;
}


/*------------------------------------------------------------------------------------------------------*/


/*CONTATTI*/


/*--MAPPA--*/

.overflowMap {
    overflow: hidden;
}

.iframeDim {
    width: 100%;
    height: 100%;
}


/*TESTO CONTATTI*/

.noPadding {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
}

.Asstr {
    position: relative;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
}

.centrotr {
    text-align: center;
    align-items: center;
}

.photo-grid1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    grid-auto-rows: 200px;
    text-align: center !important;
    z-index: 99999;
}

@media screen and (min-width: 1185px) {
    .photo-grid1 {
        grid-auto-rows: 200px;
    }
}

.photo-grid2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    grid-auto-rows: 600px;
}

@media screen and (min-width: 1185px) {
    .photo-grid2 {
        grid-auto-rows: 600px;
    }
}

.paddingVia {
    padding-top: 50px !important;
}

.card2 {
    display: flex;
    justify-content: center; /* Centra orizzontalmente */
    align-items: center; /* Centra verticalmente */
    border-radius: 0px !important;
    font-family: 'Bebas Neue', cursive;
    color: black !important;
    font-size: 3.3vw;
    vertical-align: middle !important; /* Rimuovo questa proprietà, non è necessaria con flexbox */
    
    text-align: center !important;
    opacity: none !important; /* Nota: "none" non è una validà opzione per l'opacità. Potrebbe essere "1" per opacità al 100% o "0" per nessuna opacità. */
    padding: none !important; /* Rimuovo questa proprietà, se vuoi rimuovere il padding, meglio usare padding: 0; */
}

.card3 {
    display: flex;
    justify-content: center; /* Centra orizzontalmente */
    align-items: center; /* Centra verticalmente */
    border-radius: 0px !important;
    font-family: 'Bebas Neue', cursive;
    color: black !important;
    font-size: 3.3vw;
    padding-top: 20px !important;
    text-align: center !important;
    
    opacity: none !important; /* Nota: "none" non è una validà opzione per l'opacità. Potrebbe essere "1" per opacità al 100% o "0" per nessuna opacità. */
}

.card4 {
    border-radius: 0px !important;
    font-family: 'Bebas Neue', cursive;
    color: black !important;
    font-size: 2.5vw;
    align-items: center !important;
    text-align: center !important;
    z-index: 9999;
    opacity: none !important;
    padding-top: 0px !important;
}

.card5 {
    font-family: 'Bebas Neue', cursive;
    color: black !important;
    font-size: 2.5vw;
    align-items: center !important;
    text-align: center !important;
    padding: 15px 0 0 0 !important;
}
.card6 {
    display: flex;
    justify-content: center; /* Centra orizzontalmente */
    align-items: center; /* Centra verticalmente */
    border-radius: 0px !important;
    font-family: 'Bebas Neue', cursive;
    color: rgb(180, 7, 7) !important;
    font-size: 4.3vw;
    padding-top: 20px !important;
    text-align: center !important;
    opacity: none !important; /* Nota: "none" non è una validà opzione per l'opacità. Potrebbe essere "1" per opacità al 100% o "0" per nessuna opacità. */
}

@media screen and (max-width: 600px) {
    .card2 {
        border-radius: 0px !important;
        font-family: 'Bebas Neue', cursive;
        color: black !important;
        font-size: 4.5vw;
        align-items: center;
        text-align: center;
        opacity: none !important;
    }
    .card3 {
        border-radius: 0px !important;
        font-family: 'Bebas Neue', cursive;
        color: black !important;
        font-size: 4.5vw !important;
        padding-top: 30px !important;
        align-items: center;
        text-align: center;
        opacity: none !important;
    }
    .card4 {
        border-radius: 0px !important;
        font-family: 'Bebas Neue', cursive;
        color: black !important;
        font-size: 4.0vw;
        align-items: center !important;
        text-align: center !important;
        z-index: 9999;
        opacity: none !important;
        padding-top: 0px !important;
    }
}

@media screen and (min-width: 1270px) {
    .card2 {
        border-radius: 0px !important;
        font-family: 'Bebas Neue', cursive;
        color: black !important;
        font-size: 2.0vw;
        align-items: center;
        text-align: center;
        opacity: none !important;
    }
    .card3 {
        border-radius: 0px !important;
        font-family: 'Bebas Neue', cursive;
        color: black !important;
        font-size: 2.0vw;
        align-items: center;
        text-align: center;
        opacity: none !important;
    }
}

@media screen and (min-width: 1550px) {
    .card2 {
        border-radius: 0px !important;
        font-family: 'Bebas Neue', cursive;
        color: black !important;
        font-size: 1.8vw;
        align-items: center;
        text-align: center;
        opacity: none !important;
    }
    .card3 {
        border-radius: 0px !important;
        font-family: 'Bebas Neue', cursive;
        color: black !important;
        font-size: 1.8vw;
        align-items: center;
        text-align: center;
        opacity: none !important;
    }
}

.imgSize1 {
    background-size: cover;
    overflow: hidden;
    padding: none !important;
}

.sfondoContatti1 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.733), rgba(255, 255, 255, 0.5)), url("img/mi.avif") !important;
    background-size: cover;
    width: 100%;
    z-index: 1;
}

.sfondoContatti2 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.733), rgba(255, 255, 255, 0.5)), url("img/tempo.avif") !important;
    background-size: cover;
    z-index: 1;
}

.sfondoContatti3 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.733), rgba(255, 255, 255, 0.5)), url("img/preno.avif") !important;
    background-size: cover;
    z-index: 1;
}




.modal-90w {
    z-index: 9999 !important;
}

.fb1 {
    color: black;
    text-decoration: none;
}

.fb1:hover {
    color: #0d6efd !important;
}

.ig1 {
    color: black;
    text-decoration: none;
}

.ig1:hover {
    color: #FF7514 !important;
}

.tree1 {
    color: black;
    text-decoration: none;
}

.tree1:hover {
    color: teal !important;
    z-index: 999;
}


/*------------------------------------------------------------------------------------------------------*/


/*FOOTER*/

.footer {
    margin-top: 0.1px !important;
    font-family: 'Bebas Neue', cursive;
    color: white;
    font-size: 15px;
    text-align: center;
    background-color: black;
    margin: 0 0 0 0;
}

.table {
    margin: 0 0 0 0;
    color: white;
}

.testSx {
    text-align: left !important;
    margin-left: -15px !important;
    background-color: black !important;

}

.testCx {
    text-align: center;
    margin: 0 0 0 0 !important;
    font-size: 1.4vw;
    font-family: 'Bebas Neue', cursive !important;
    background-color: black !important;
    color: white !important
}

.testDx {
    text-align: right;
    align-items: right;
    padding: 0 0 0 0 !important;
    background-color: black !important;
}


.fa {
    margin-left: 5px;
    margin-right: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .testCx {
        font-size: 18px; /* scegliere la dimensione del font appropriata */
        display: block;
    }
}

@media only screen and (max-width: 715px) {
    .testCx {
        font-size: 2.5vw;
        display: block;
    }
}

@media only screen and (max-width: 677px) {
    .testSx {
        size: 10px !important;
    }
    .testDx {
        size: 10px !important;
    }
}

@media only screen and (max-width: 420px) {
    .testCx {
        font-size: 0.8em !important;
        text-align: center;
    }
    .fa-2x {
        font-size: 1.3em !important;
        font: 1.3em !important;
    }
}

@media only screen and (max-width: 351px) {
    .testSx {
        font-size: 1em !important;
    }
    .testCx {
        font-size: 12px !important;
    }
    .fa-2x {
        font-size: 1em !important;
        font: 1em !important;
    }
}


/*FINE FOOTER*/

p {
    margin: 0 0 0 0 !important;
}

.fb {
    color: white;
}

.fb:hover {
    color: #0d6efd;
}

.ig {
    color: white;
}

.ig:hover {
    color: orange;
}

.tree {
    color: white;
}

.tree:hover {
    color: teal;
}

.coloreCookie{
    color: white;
    text-decoration: none;
}
.colorePrivacy{
    color: white;
    text-decoration: none;
}

.coloreCookie:hover{
    color:aquamarine;
    text-decoration: none;
}
.colorePrivacy:hover{
    color:aquamarine;
    text-decoration: none;
}


/*SCROLLBAR*/

body::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-track {
    box-shadow: white;
}

body::-webkit-scrollbar-thumb {
    background-color: black;
    outline: 1px solid black;
    border-radius: 5%;
}

.storia::-webkit-scrollbar {
    width: 0.05em;
}

.storia::-webkit-scrollbar-track {
    box-shadow: black;
}

.storia::-webkit-scrollbar-thumb {
    background-color: rgb(230, 183, 96);
    outline: 1px solid rgb(230, 183, 96);
}


.vlecvifooter{
    color: white !important;
    z-index: 999;
    background-color: black !important;
}
.vlecvifooter:hover{
    color: white !important;
    z-index: 999;
    background-color: black !important;

}

.vlecviname{
    color: rgb(168, 255, 226) !important;
}

.testoCookie{
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}


/* FINE SCROLLBAR*/